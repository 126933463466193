.footerWrapper {
    width: 100vw;
    background-color: #272532;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.pUnderLogo {
    font-size: 9px;
}

.logoBackgroundMini {
    height: 33px;
    background-color: #5E3A9E;
    width: 107px;
    padding-left: 3px;
    padding-top: 1px;
}