.navBarWrapper {
    width: 100vw;
    height: 85px;
    background-color: #5E3A9E;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navBar {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    margin: 0;
}

.navBar li {
    list-style: none;
    cursor: pointer;
}

.navBar a {
    color: white;
    text-decoration: none;
}