.dashboardMenu {
    background-color: #272532;
    color: #fff;
    font-size: 1.1em;
    padding: 2em;
    border-radius: 0.3em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.smallElogo {
    background-color: #5E3A9E;
    padding: 0px 6px 2px 5px;
    border: 2px solid;
    margin-right: 10px;
    border-radius: 6px;
    margin-left: 10px;
}

.Homepage_dashboardMenu {
    position: relative;
    left: -5px;
}

.menuList {
    list-style: none;
    font-size: 1em;
    letter-spacing: 0px;
    line-height: 4em;
}