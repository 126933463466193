.modal {
    width: 80vw;
    max-width: 400px;
    height: 500px;
    position: absolute;
    min-height: 255px;
    top: 5%;
    left: 50%;
    transform: translate(-50%, 0%);
    border-radius: 20px;
    background-color: #fff;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modalHeader {
    color: #331f57;
}
.modalBackground {
    position: fixed;
    width: 200vw;
    height: 200vw;
    background-color: #463059;
    z-index: 100;
    opacity: .75;
}

.getStarted {
    font-size: 14px;
    line-height: 18px;
}

.inputFields {
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
}