h1,
h2,
h3,
h4,
h5,
p,
li,
a {
    font-family: "Poppins", sans-serif;
}

body {
    margin: 0px;
}

.logo a {
    color: white;
    text-decoration: none;
}

.logo {
    font-size: 25px;
    border: 2px solid white;
    padding: 10px;
    list-style-type: none;
}

.logo-mini {
    font-size: 16px;
    border: 1.3px solid white;
    padding: 3.4px;
    color: white;
    text-decoration: none;
    list-style-type: none;
    background-color: #5E3A9E;
}

.signup-modal-open {
    filter: blur(2px); 
    pointer-events: none;
}


#unblurred {
    width: 100px;
    height: 100px;
    background-color: black;
    position: absolute;
    z-index: 10000;
}

#unblurred #box {
    background:yellow; 
    border:2px solid black; 
    filter: blur(0px);
}
.LoadingDiv {
    background-color: #5E3A9E;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    color: white;
    padding-top: 100px;
}
@supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px))) {
    .example-class {
      background-color: rgba(255, 255, 255, .7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }