.homepageWrapper {
    width: 100%;
}

.bannerText {
    text-transform: lowercase;
    font-size: 4vw;
    line-height: 4vw;
    font-weight: 500;
    color: #331f57;
    letter-spacing: 1px;
}
@media only screen and (max-width: 480px) {
    .bannerText {
        font-size: 24px!important;
    }
}
.dashboardMenu {
    background-color: #272532;
    color: #fff;
    font-size: 1.1em;
    padding: 2em;
    border-radius: 0.3em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.smallElogo {
    background-color: #5E3A9E;
    padding: 0px 6px 2px 5px;
    border: 2px solid;
    margin-right: 10px;
    border-radius: 6px;
    margin-left: 10px;
}

.Homepage_dashboardMenu {
    position: relative;
    left: -5px;
}

.bannerMissionStmt {
    background-color: #e4dce8;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;
}
.menuList {
    list-style: none;
    font-size: 1em;
    letter-spacing: 0px;
    line-height: 4em;
}

.bannerSubText {
    font-size: 12px;
    margin-top: 20px;
    width: 75%;
}

.bannerImageCLS {
    width: 100%;
    min-height: 39vw;
}