.googleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    height: 50px;
    background: #fff;
    position: relative;
    color: black;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.facebookContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    height: 50px;
    background: #3D5B96;
    position: relative;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.googleSvg, .facebookSvg {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.loginTagline {
    font-size: 2.5rem;
    color: #29164b;
    font-weight: 900;
}
.loginBox {
    margin-top: 2%;
    border-radius: 18px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #f5f0f6;
}
.loginHeader {
    font-weight: 900;
    color: #29164b;
    margin: auto;
    text-align: center;
    padding-bottom: 4%;
    letter-spacing: 2px;
}
.googleContainer img {
    width: 12.5%;
    height: 100%;
    object-fit: contain;
    left: 10px;
    border-radius: 3px;
    position: absolute;
}

.githubContainer {
    background: rgb(56, 56, 56);
}

.twitterContainer {
    background: #00a2f4;
}

.twitterContainer img {
    width: 15%;
    left: 8px;
}

.loginPage {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #e6ddee;
}

.loginForm {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    flex-direction: column;
    min-width: 40vw;
}

tbody :not(#unblurred){
    filter: blur(2px);    
}

@media only screen and (max-width: 480px) {
    .loginTagline {
      font-size: 24px;
    }
  }
